<template>
  <div class="sp-select-field" :class="[classVariant]" :data-test-id="elementTest" @click="toggle()" @blur="toggle()">
    <div
      v-if="iconLeft != ''"
      class="sp-select-icon-left"
      :style="`background-image: url('${iconLeft}');`"
      grey
      :data-test-id="`${elementTest}-icon-left`"
    />
    <div v-if="hasIconSlot" class="sp-select-icon-left" :data-test-id="`${elementTest}-icon-left`">
      <slot name="icon" />
    </div>
    <div class="sp-select-field-div" :data-test-id="`${elementTest}-field`">
      <span
        class="sp-select"
        :has-icon-slot="hasIconSlot"
        :class="selectedValue ? 'selected' : ''"
        :data-test-id="`${elementTest}-${selectedValue ? 'selected' : 'placeholder'}`"
      >
        {{ selectedValue || placeholderValue }}
        <span v-if="!selectedValue" class="sp-select-field-required" :data-test-id="`${elementTest}-required`">{{
          required ? ' *' : ''
        }}</span>
      </span>
    </div>
    <div
      class="sp-select-icon u-bg-steel-grey"
      :class="fieldFocus ? 'icon-up' : 'icon-down'"
      :data-test-id="`${elementTest}-${fieldFocus ? 'icon-up' : 'icon-down'}`"
    />
    <div v-if="hasError" class="error sp-select-error" :data-test-id="`${elementTest}-error`">
      <p>
        {{ error }}
      </p>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  modelValue: { type: null, default: null },
  classVariant: { type: null, default: null },
  selectedValue: { type: [String, Number], default: '' },
  placeholderValue: { type: String, default: '' },
  error: { type: String, default: '' },
  icon: { type: String, default: '' },
  iconLeft: { type: String, default: '' },
  required: { type: Boolean, default: false },
  elementTest: { type: String, default: '' },
  inputFormatter: {
    type: Function,
    default(input) {
      return input
    },
  },
})
const emit = defineEmits(['on-value', 'update:modelValue', 'clear'])
const slots = useSlots()
const inputValue = ref(props.value)
const activeInput = ref(false)
const fieldFocus = ref(false)

const hasError = computed(() => {
  return props.error.length !== 0
})
const hasIconSlot = computed(() => {
  return !!slots.icon
})

watch(
  () => props.modelValue,
  v => {
    updateValue(v)
  }
)
watch(
  () => inputValue.value,
  v => {
    updateValue(v)
  }
)
onMounted(() => {
  manageBlur()
})
const toggle = () => {
  fieldFocus.value = !fieldFocus.value

  emit(fieldFocus.value ? 'focus' : 'blur')
}

const manageBlur = () => {
  if (props.modelValue !== null && props.modelValue !== undefined) {
    fieldFocus.value = props.modelValue.length > 0 || props.modelValue instanceof Date
  } else {
    fieldFocus.value = false
  }
}

const toggleShowPassword = () => {
  if (selectedInputType.value === 'password') {
    selectedInputType.value = 'text'
  } else {
    selectedInputType.value = 'password'
  }
}
const clear = () => {
  inputValue.value = ''
  emit('clear')
}
const toggleFocus = () => {
  fieldFocus.value = !fieldFocus.value
}

defineExpose({
  manageBlur,
  toggleShowPassword,
  clear,
  toggleFocus,
})

const updateValue = newValue => {
  inputValue.value = props.inputFormatter(newValue)
  activeInput.value = (inputValue.value && inputValue.value.length > 0) || inputValue.value instanceof Date
  emit('on-value', props.inputFormatter(inputValue.value))
  emit('update:modelValue', props.inputFormatter(inputValue.value))
}
</script>

<style lang="scss">
.sp-select-field {
  position: relative;
  padding: 0;
  background-color: rgba(151, 151, 151, 0.1);
  display: flex;

  &.full-width {
    width: 100%;
  }

  &-required {
    padding-left: $spacing-xxs;
  }

  .sp-select-error {
    margin: $spacing-xs 0;
  }

  &.bg-grey {
    background-color: var(--bg-grey);
  }

  &.radius-md {
    border-radius: $spacing-xs !important;
  }

  &.radius-sm {
    border-radius: $spacing-xxs !important;
  }
}

.sp-select-field-div {
  width: 100%;
  position: relative;
}

.sp-select-field input:focus {
  outline: none;
}

.sp-select-field input:focus::placeholder {
  color: transparent;
}

.sp-select {
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  border: 0;
  width: 100%;
  height: 40px;
  caret-color: var(--primary);
  border-radius: $spacing-xxs;
  font-family: 'Avenir';
  font-size: pxToRem(12px);
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: var(--steel-grey);

  &.selected {
    color: var(--dark-blue) !important;
    font-size: pxToRem(12px);
    font-weight: 600;
  }

  &.error {
    margin-top: 0;
    margin-bottom: $spacing-sm;
  }

  &[has-icon-slot] {
    padding-left: 0;
  }
}

.activeInput {
  color: var(--dark-blue) !important;
  font-size: pxToRem(12px);
  font-weight: 600;
}

.sp-select.error {
  background-color: var(--grapefruit);
}

.sp-select-field div .error {
  font-size: pxToRem(13px);
  font-family: Avenir;
  color: var(--red);
  float: left;
}

.sp-select-icon,
.sp-select-icon-left {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: 10px;
  right: 0;
  top: 0;
}

.sp-select-icon {
  position: relative;
  top: 1px;
  margin-right: $spacing-sm;
}

.sp-select-icon-left {
  left: 0;
  background-position: $spacing-xs;
}
</style>
